import TestLayout from "@/pcomponents/test/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import strFunc from '@/lib/lyg/string';

function URLStrPage(){
  const [rowData,setRowData]=useState<any>({
    "reverse_slash":"",
    "slash":"",
    "api_url":"",
    "front":"",
  });

  const handleInputChange=(event:any)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // setRowData({
    //   ...rowData,
    //   ...{[name]: value}
    // });
    onChangeSlashInput(value);
  };

  const onChangeSlashInput=(str:string)=>{
    let reverse_slash=strFunc.str_replace("/","\\",str);
    let slash=strFunc.str_replace("\\","/",str);
    let api_url=strFunc.str_replace("node/routes/","",slash);
    api_url=strFunc.str_replace("node/","",api_url);
    api_url=strFunc.str_replace(".js","",api_url);
    let front=strFunc.str_replace("front/src/","",slash);
    front=strFunc.str_replace("front_m/src/","",front);
    front=strFunc.str_replace("/index.tsx","",front);
    front=strFunc.str_replace(".tsx","",front);
    setRowData({
      "reverse_slash":reverse_slash,
      "slash":slash,
      "api_url":api_url,
      "front":front,
    });
  };

  const copyContext=(key:string)=>{
    navigator.clipboard.writeText(rowData[key]);
  };

  return (
    <TestLayout>
      <div className="con_wrap">
        <div className="write-table-div" style={{width:1024,margin:"0 auto",marginTop:50}}>
          <table>
            <colgroup>
              <col width="120px"></col>
              <col width="*"></col>
              <col width="120px"></col>
              <col width="*"></col>
            </colgroup>
            <tbody>
              <tr>
                <th>
                  역슬래쉬
                  <button className="btn-s btn-gray" onClick={()=>{copyContext("reverse_slash");}}>복사</button>
                </th>
                <td>
                  <input type="text" className="row_input" name="reverse_slash" value={rowData.reverse_slash}
                  onChange={handleInputChange} placeholder="reverse_slash" style={{color:"red"}}/>
                </td>
                <th>
                  슬래쉬
                  <button className="btn-s btn-gray" onClick={()=>{copyContext("slash");}}>복사</button>
                </th>
                <td>
                  <input type="text" className="row_input" name="slash" value={rowData.slash} 
                  onChange={handleInputChange} placeholder="slash" style={{color:"blue"}} />
                </td>
              </tr>
              <tr>
                <th>
                  APIURL
                  <button className="btn-s btn-gray" onClick={()=>{copyContext("api_url");}}>복사</button>
                </th>
                <td>
                  <input type="text" className="row_input" name="api_url" value={rowData.api_url} onChange={handleInputChange} placeholder="api_url" />
                </td>
                <th>
                  FRONT
                  <button className="btn-s btn-gray" onClick={()=>{copyContext("front");}}>복사</button>
                </th>
                <td>
                  <input type="text" className="row_input" name="front" value={rowData.front} onChange={handleInputChange} placeholder="front" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </TestLayout>
  );
}

export default URLStrPage;