import NoteLayout from "@/pcomponents/note/layout";
import { useState, useRef, useEffect, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from "@/lib/lyg/string";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import UserViewPopup from "../popup/view";
import UserWritePopup from "../popup/write";

function UserListPage() {
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt: {
      ...xColumnArr.list_opt
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "write",//view
    isUpdate: false,
    rowData: {},
    title: "팝업",
    width: "800px",
    height: "85%",
  });

  useEffect(() => {
    
  }, []);

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData = pageData;
      inOptObj["sc"] = listAggridRef.current.getListSc();
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/list', pageData.listOpt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const deleteByAjax = () => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }

    if (!window.confirm("삭제 하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      "data_arr": selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/delete', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({ "now_page": "1" });
        } else {

        }
      });
  };

  const openViewPopup = () => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    } else if (selected_row.length != 1) {
      alert("한개만 선택해 주세요.");
      return false;
    }
    let row_data = null;
    if (selected_row.length > 0) {
      row_data = selected_row[0];
    }
    setPopupData({
      ...popupData,
      isOpen: true,
      sort: "view",
      isUpdate: false,
      rowData: row_data,
      title: "팝업",
    });
  };
  const openWritePopup = (inData: any) => {
    let opt_obj = {
      isUpdate: false,
      ...inData
    };
    let row_data = null;
    let title_str = "등록";
    if (opt_obj["isUpdate"]) {
      title_str = "수정";
      let selected_row = listAggridRef.current.getSelectedRows();
      if (selected_row.length == 0) {
        alert("선택이 없습니다.");
        return false;
      } else if (selected_row.length != 1) {
        alert("한개만 선택해 주세요.");
        return false;
      }

      if (selected_row.length > 0) {
        row_data = selected_row[0];
      }
    }

    setPopupData({
      ...popupData,
      isOpen: true,
      sort: "write",
      isUpdate: opt_obj["isUpdate"],
      rowData: row_data,
      title: title_str,
    });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_user_name") {
      setTimeout(() => {
        openViewPopup();
      }, 100);
    }
  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    return render_str;
  }, []);

  return (
    <NoteLayout>
      유저리스트
      <div>
        <button className="btn btn_gray" onClick={openWritePopup}>등록</button>
        <button className="btn btn_gray ml-1" onClick={openViewPopup}>보기</button>
        <button className="btn btn_gray ml-1" onClick={() => { openWritePopup({ "isUpdate": true }); }}>수정</button>
        <button className="btn btn_gray ml-1" onClick={deleteByAjax}>삭제</button>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: false,
          is_add_checkbox: false,
          floatingFilter: true,
          onGridReady: () => {
            list({});
          },
          onCellClicked: cellClickedListener,
          cellRenderer: cellRenderer
        }}
      ></ListAggrid>
      <Paging now_page={pageData.listOpt.now_page}
        num_per_page={pageData.listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
        onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "view" &&
            <UserViewPopup
              rowData={popupData.rowData} callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></UserViewPopup>
          }
          {popupData.sort === "write" &&
            <UserWritePopup
              isUpdate={popupData.isUpdate}
              rowData={popupData.rowData}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></UserWritePopup>
          }
        </LayerPopup>
      }
    </NoteLayout>
  );
};
export default UserListPage;