import {
  createBrowserRouter,
} from "react-router-dom";

import testRouterArr from "./test/index";
import noteRouterArr from "./note/index";

const router = createBrowserRouter([
  ...testRouterArr,
  ...noteRouterArr,
  {
    path: "*",
    element: <div>404 Not Found!!</div>,
  },
]);

export default router;