import NoteLayout from "@/pcomponents/note/layout";
import { useSelector, useDispatch } from 'react-redux';

function MainPage(){
  let user=useSelector((state:any) => state.user);

  return (
    <NoteLayout>
      메인 {user.user_name}
    </NoteLayout>
  );
}
export default MainPage;