function WriteArea(props: any) {
  let myProps = {
    row_data: {
      "a_user_id": "",
      "a_user_pw": "",
      "a_user_name": "",
      "a_user_nickname": "",
    },
    set_row_data: (inData: any) => { },
    ...props
  };
  let row_data = myProps.row_data;

  const on_change_input = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let change_row_data = { ...row_data };
    change_row_data[name] = value;
    myProps.set_row_data(change_row_data);
  };

  return (
    <div className="write-table-div">
      <table>
        <colgroup>
          <col width={"20%"}></col>
          <col width={"30%"}></col>
          <col width={"20%"}></col>
          <col width={"30%"}></col>
        </colgroup>
        <tbody>
          <tr>
            <th>ID</th>
            <td colSpan={3}>
              <input type="text" value={row_data.a_user_id} className="row_input"
                name="a_user_id"
                onChange={on_change_input} />
            </td>
          </tr>
          <tr>
            <th>PW</th>
            <td colSpan={3}>
              <input type="password" value={row_data.a_user_pw} className="row_input"
                name="a_user_pw"
                onChange={on_change_input} />
            </td>
          </tr>
          <tr>
            <th>이름</th>
            <td colSpan={3}>
              <input type="text" value={row_data.a_user_name} className="row_input"
                name="a_user_name"
                onChange={on_change_input} />
            </td>
          </tr>
          <tr>
            <th>닉네임</th>
            <td colSpan={3}>
              <input type="text" value={row_data.a_user_nickname} className="row_input"
                name="a_user_nickname"
                onChange={on_change_input} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default WriteArea;