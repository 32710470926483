import Style from "./css/stye.module.css";
import { useState, useEffect,useRef } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '@/store/user/user';
import CheckLoginComponent from "@/pcomponents/note/check/login_check";

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login_data, set_login_data] = useState({
    id: "",
    pw: "",
  });
  const id_input_ref=useRef<HTMLInputElement>(null);

  useEffect(() => {
    
    id_input_ref.current?.focus();
  }, []);

  const on_change_input = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;

    set_login_data({
      ...login_data,
      [name]: value
    });
  };

  const go_login = () => {
    if (login_data.id == "") {
      alert("아이디 입력이 필요합니다.");
      return false;
    }
    if (login_data.pw == "") {
      alert("암호 입력이 필요합니다.");
      return false;
    }

    let login_form_data = {
      id: login_data.id,
      pw: login_data.pw
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/login/login/login', login_form_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          localStorage.setItem('login_token', response.data.data["token_id"]);
          var client_info = response.data.data["client_info"];
          dispatch(setUser(client_info));
          localStorage.sort_data_arr = JSON.stringify(client_info.sort_data);
          navigate("/note/note/diary/list");
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div className={Style.login_wrap}>
      <CheckLoginComponent isGoToMainAtLogin={true}></CheckLoginComponent>
      <div className={Style.login_title}>
        로그인
      </div>
      <table className={Style.login_table}>
        <colgroup>
          <col width={"80px"}></col>
          <col width={"*"}></col>
        </colgroup>
        <tbody>
          <tr>
            <th>ID</th>
            <td>
              <input type="text" name="id" value={login_data.id}
                onChange={on_change_input} placeholder="아이디"
                autoComplete="off" ref={id_input_ref}/>
            </td>
          </tr>
          <tr>
            <th>PW</th>
            <td>
              <input type="password" name="pw" value={login_data.pw}
                onChange={on_change_input} placeholder="암호"
                autoComplete="off"
                onKeyUp={(e: any) => {
                  if (e.key === "Enter") {
                    go_login();
                  }
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className={Style.login_btn_wrap}>
        <button className={Style.login_btn} onClick={go_login}>로그인</button>
        <button className={Style.login_btn} onClick={() => { navigate("/join"); }}
          style={{ marginTop: "10px", background: "#616161" }} >회원가입</button>
      </div>
    </div>
  );
}
export default LoginPage;