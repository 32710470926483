import { lazy } from 'react';

import TestMain from "@/pages/test";
import DBConnectPage from "@/pages/test/db/connect";
import UrlStrPage from "@//pages/test/util/url_str";

const routerArr:object[] = [
  {
    path: "/test",
    element: <TestMain></TestMain>,
  },
  {
    path: "/test/db/connect",
    element: <DBConnectPage></DBConnectPage>,
  },
  {
    path: "/test/util/url_str",
    element: <UrlStrPage></UrlStrPage>,
  },
];

export default routerArr;