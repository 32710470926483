export interface HeadMenuRow {
  key: string;
  name: string;
  url: string;
  msg: string;
  isShow: boolean;
}
export interface SubMenuRow {
  key: string;
  name: string;
  url: string;
  msg: string;
  hmenu:string;
  isShow: boolean;
}

export const hmenu:HeadMenuRow[]=[
  {key:"main",name:"메인",url:"/main",msg:"",isShow:false},
  {key:"note",name:"노트",url:"/note/note/diary/list",msg:"",isShow:true},
  {key:"manage",name:"관리",url:"/note/basic/user/list",msg:"",isShow:true},
];
export const smenu:SubMenuRow[]=[
  {key:"main",name:"메인",url:"/main",msg:"",hmenu:"main",isShow:true},

  {key:"mypage",name:"마이페이지",url:"/note/mypage",msg:"",hmenu:"manage",isShow:false},
  {key:"user_list",name:"유저",url:"/note/basic/user/list",msg:"",hmenu:"manage",isShow:true},
  {key:"diary_list",name:"일기",url:"/note/note/diary/list",msg:"",hmenu:"note",isShow:true},
  {key:"memo_list",name:"메모",url:"/note/note/memo/list",msg:"",hmenu:"note",isShow:true},
];