import TestLayout from "@/pcomponents/test/layout/layout";

const TestMain = ()=>{
  return (
    <TestLayout>
      메인페이지
    </TestLayout>
  );
};

export default TestMain;