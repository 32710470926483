import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"user",
    list_id:"user_list",
    x_column_list_arr:[
      {"key":"a_user_name","name":"이름","width":"110","is_show":"1"},
      {"key":"a_user_nickname","name":"넥네임","width":"110","is_show":"1"},
      {"key":"a_user_id","name":"아이디","width":"120","is_show":"1"},
      {"key":"a_user_grade","name":"등급","width":"100","is_show":"1"},
      {"key":"a_last_login_date","name":"마지막 로그인","width":"160","is_show":"1"},
      {"key":"a_create_date","name":"작성일","width":"180","is_show":"1"},
      {"key":"a_update_date","name":"수정일","width":"180","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;