import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
function DiarayViewPopup(props: any) {
  let myProps = {
    rowData: {},
    closePopup: (inData: any) => { },
    ...props
  };
  let rowData = myProps.rowData;

  return (
    <div>
      <div className="write-table-div">
        <table>
          <colgroup>
            <col width={"20%"}></col>
            <col width={"30%"}></col>
            <col width={"20%"}></col>
            <col width={"30%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th>카테고리</th>
              <td colSpan={3}>
                {rowData.a_category}
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td colSpan={3}>
                {rowData.a_title}
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <MultyLineTextView
                  text={rowData.a_content}
                ></MultyLineTextView>
              </td>
            </tr>
            <tr>
              <th>작성자</th>
              <td>
                {rowData.a_writer}
              </td>
              <th>날짜</th>
              <td>
                {rowData.a_select_date}
              </td>
            </tr>
            <tr>
              <th>작성일</th>
              <td>
                {rowData.a_create_date}
              </td>
              <th>수정일</th>
              <td>
                {rowData.a_update_date}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center" style={{ marginTop: "10px" }}>
        <button className="btn btn_gray" onClick={() => { props.closePopup(); }}>닫기</button>
      </div>
    </div>
  );
};
export default DiarayViewPopup;