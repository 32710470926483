import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
function UserViewPopup(props: any) {
  let myProps = {
    rowData: {},
    closePopup: (inData: any) => { },
    ...props
  };
  let rowData = myProps.rowData;

  return (
    <div>
      <div className="write-table-div">
        <table>
          <colgroup>
            <col width={"20%"}></col>
            <col width={"30%"}></col>
            <col width={"20%"}></col>
            <col width={"30%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th>이름</th>
              <td>
                {rowData.a_user_name}
              </td>
              <th>닉네임</th>
              <td>
                {rowData.a_user_nickname}
              </td>
            </tr>
            <tr>
              <th>아이디</th>
              <td>
                {rowData.a_user_id}
              </td>
              <th>등급</th>
              <td>
                {rowData.a_user_grade}
              </td>
            </tr>
            <tr>
              <th>핸드폰</th>
              <td>
                {rowData.a_user_phone}
              </td>
              <th>이메일</th>
              <td>
                {rowData.a_email}
              </td>
            </tr>
            <tr>
              <th>마지막로그인</th>
              <td colSpan={3}>
                {rowData.a_last_login_date}
              </td>
            </tr>
            <tr>
              <th>작성일</th>
              <td>
                {rowData.a_create_date}
              </td>
              <th>수정일</th>
              <td>
                {rowData.a_update_date}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center" style={{ marginTop: "10px" }}>
        <button className="btn btn_gray" onClick={() => { props.closePopup(); }}>닫기</button>
      </div>
    </div>
  );
};
export default UserViewPopup;