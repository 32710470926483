//import NoteLayout from "@/pcomponents/note/layout";
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setDark } from '@/store/dark/dark';
import Header from "./header";
import Footer from "./footer";

const NoteLayout=(props:any)=>{
  const dispatch = useDispatch();
  let dark=useSelector((state:any) => state.dark);
  let dark_class_name="light";
  if(localStorage.theme=="dark"){
    dark_class_name=localStorage.theme;
  }
  useEffect(()=>{
    setDarkClass();
  },[dark]);

  const setDarkClass=()=>{
    if(dark.dark==""){
      if(localStorage.theme === 'dark' ){
        dispatch(setDark("dark"));
      }else{
        dispatch(setDark("light"));
      }
    }
  };

  return (
    <div id="main_wrap" className={dark_class_name} >
      <div className="main_body">
        <Header></Header>
        <div id="container">
          <div className="container_con">
            {props.children}
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default NoteLayout;