import GNBArea from "../menu/gnb";
import SNBArea from "../menu/snb";
import { useSelector, useDispatch } from 'react-redux';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useEffect, useState, useRef } from 'react';
import { setUser, removeUser } from '@/store/user/user';
import { Link, useNavigate } from 'react-router-dom';
import CheckLoginComponent from "@/pcomponents/note/check/login_check";

const Header = () => {
  const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    
  }, []);

  const goLogOut = () => {
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/login/login/logout', {
      token_id: localStorage.login_token,
    },get_axios_data()).then((response) => {
      dispatch(removeUser());
      localStorage.removeItem("login_token");
      navigate("/login");
    });
  };
  return (
    <div>
      <CheckLoginComponent></CheckLoginComponent>
      <div className="header_title_wrap">
        {user.user_name}
        <span className="header_title_right">
          <button className="btn btn_gray"
            onClick={() => { goLogOut(); }}>로그아웃</button>
        </span>
      </div>
      <GNBArea></GNBArea>
      <SNBArea></SNBArea>
    </div>
  );
};

export default Header;