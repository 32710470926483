import TestLayout from "@/pcomponents/test/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback, forwardRef } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";

const DBConnectPage = () => {
  const [is_connect, set_is_connect] = useState(false);
  const countRef = useRef(0);
  const [count, set_count] = useState(0);

  useEffect(() => {
    const count_loop = setInterval(countInterval, 1000);
    return () => {
      clearInterval(count_loop);
    };
  }, []);

  const countInterval = () => {
    countRef.current++;
    set_count(countRef.current);
  };

  useEffect(() => {
    
  }, []);

  const checkConnectDb = () => {
    let check_db_form_json = {

    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/test/mytest/sql_test', check_db_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_is_connect(true);
          setTimeout(() => {
            set_is_connect(false);
          }, 2000);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <TestLayout>
      <button onClick={() => { checkConnectDb(); }}>연결테스트</button>
      {is_connect &&
        <div style={{ color: "blue" }}>연결됨</div>
      }
      <div>
        카운트 {count}
      </div>
    </TestLayout>
  );
};

export default DBConnectPage;