import { useSelector, useDispatch } from 'react-redux';
import { Link,useNavigate } from 'react-router-dom';
import {hmenu,smenu} from "@/pcomponents/test/layout/menu/data/menu_arr";

const SNBArea=(props:any)=>{
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let user=useSelector((state:any) => state.user);

  const movePage=(url:string)=>{
    navigate(url);
  };

  let now_h_key="";
  for(let i=0;i<smenu.length;i++){
    if(smenu[i].url==window.location.pathname){
      now_h_key=smenu[i].hmenu;
    }
  }
  
  const menuArrTags=smenu.map((item,idx)=>{
    if(item.hmenu!=now_h_key){
      return "";
    }
    if(item.isShow==false){
      return "";
    }
    let snb_class_name="snb_item";
    if(item.url==window.location.pathname){
      snb_class_name+=" active";
    }
    
    return (
      <div key={idx} className={snb_class_name} onClick={()=>{movePage(item.url);}} >
        <div className="snb_item_text" >
          {item.name}
        </div>
      </div>
    );
  });

  return (
    <div className="snb_area_wrap">
      <div className="snb_area">
        <div className="snb_area_con">
          {menuArrTags}
        </div>
      </div>
    </div>
  );
};

export default SNBArea;