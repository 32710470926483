//import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
function MultyLineTextView(props:any){
   let myProps={
    text:"",
    ...props
  };
  myProps.text=String(myProps.text)+"";
  
  return (
  <>
    {
      myProps.text && myProps.text.split('\n').map( (line:string,idx:number) => {
        return (<span key={idx}>{line}<br/></span>)
      })
    }
  </>
  );
};
export default MultyLineTextView;