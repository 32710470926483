import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '@/store/user/user';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';

function CheckLoginComponent(props: any) {
  let myProps={
    "isGoToMainAtLogin": false,
    "onGoLoginPage": () => { },
    ...props
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const [pageData, setPageData] = useState({
    isGoToMainAtLogin: false,
    "onGoLoginPage": () => { },
    ...props
  });

  useEffect(() => {
    
    checkLogin();
  }, []);

  const checkLogin = () => {
    //키가있으면 로그인체크
    if (localStorage.login_token) {
      let check_form_json:any = {
        token_id: localStorage.login_token,
      };
      if(localStorage.login_token_create_date==DateFunc.get_date_format(new Date(),"Y-m-d h")){
        check_form_json["is_add_client_info"]="";
      }else{
        check_form_json["is_add_client_info"]="1";
        check_form_json["is_refresh"]="1";
      }
      if(strFunc.is_empty(user.user_seq)){
        check_form_json["is_add_client_info"]="1";
      }
      my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/login/login/check_login', check_form_json,get_axios_data())
        .then((response) => {
          if (response.data["result"] === "true") {
            localStorage.login_token=response.data.data["token_id"];
            if(response.data.data["client_info"]){
              localStorage.login_token_create_date=DateFunc.get_date_format(new Date(),"Y-m-d h");
              let pre_user_seq = user.user_seq;
              var client_info = response.data.data["client_info"];
              dispatch(setUser(client_info));
              localStorage.sort_data_arr = JSON.stringify(client_info.sort_data);
              if (strFunc.is_empty(pre_user_seq)) {
                goToMainPage(client_info.comp_sort, client_info);
              }
            }
          } else {
            goLoginPageByUrl();
          }
        })
        .catch(function (error) {
          if (error.code == "ERR_NETWORK") {
            alert("인터넷 연결 오류.");
            goLoginPageByUrl();
            // if(confirm("인터넷 연결 오류. 다시 시도 하시겠습니까?")){
            //   checkLogin();
            // }
          }
        });
    } else {
      goLoginPageByUrl();
    }
  };
  const goToMainPage = (comp_sort: string, client_info: any) => {
    let path_arr = window.location.pathname.split("/");
    if (path_arr.length > 1 && path_arr[1] == "test") {
      if (client_info.user_id != "lyg") {
        navigate("/");
      }
    }
    if (pageData.isGoToMainAtLogin) {
      navigate("/note/note/diary/list");
    }
  };
  const goLoginPageByUrl = () => {
    pageData.onGoLoginPage();
    if(window.location.pathname!="/mobile/login"){
      navigate("/login");
    }
  };

  return (
    <></>
  );
}

export default CheckLoginComponent;