import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from "@/lib/lyg/string";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import { useSelector, useDispatch } from 'react-redux';
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";

function WritePopup(props: any) {
  let myProps = {
    closePopup: (inData: any) => { },
    rowData: {},
    isUpdate: false,
    ...props
  };

  let user = useSelector((state: any) => state.user);
  const pageData = {
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
  };
  if (props.isUpdate) {
    pageData["write_btn_text"] = "수정";
    pageData["confirm_msg"] = "수정 하시겠습니까?";
  }
  const [rowData, setRowData] = useState({
    "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
    "a_seq": "",

    "a_writer_seq": user.user_seq,
    "a_writer": user.user_name,
    "a_select_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "a_category": "",
    "a_title": "",
    "a_content": "",
  });
  useEffect(() => {
    
    if (props.isUpdate) {
      let changeRowData: any = {};
      for (let key in rowData) {
        if (props.rowData[key] != undefined) {
          changeRowData[key] = props.rowData[key];
        }
      }

      setRowData({
        ...rowData,
        ...changeRowData
      });
    }
  }, []);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }

    setRowData({
      ...rowData,
      ...{ [name]: value }
    });
  };
  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    setRowData({
      ...rowData,
      ...{ [key]: date_str }
    });
  };

  const goWrite = (e: any) => {
    if (rowData.a_title == "") {
      alert("제목 입력 필요.");
      return false;
    }
    if (!window.confirm(pageData["confirm_msg"])) {
      return false;
    }

    let wRowData = { ...rowData };

    let formJsonData = {
      "data_arr": [wRowData],
      "is_default_val": "1",
      "is_update": "",
    };
    if (props.isUpdate) {
      formJsonData["is_default_val"] = "";
      formJsonData["is_update"] = "1";
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/note/memo/write', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          props.callback(response.data["data"]);
          props.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });

  };

  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="110px"></col>
            <col width="35%"></col>
            <col width="110px"></col>
            <col width="35%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>날짜</th>
              <td colSpan={3}>
                <div style={{ display: "inline-block", width: 95, marginLeft: 5 }}>
                  <DatePicker
                    selected={rowData.a_select_date != "" ? new Date(rowData.a_select_date) : null}
                    onChange={(date: Date) => {
                      onChangeDatePicker("a_select_date", date);
                    }}
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>카테고리</th>
              <td colSpan={3}>
                <input type="text" className="row_input" value={rowData.a_category} name="a_category"
                  onChange={handleInputChange}/>
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td colSpan={3}>
                <input type="text" className="row_input" value={rowData.a_title} name="a_title"
                  onChange={handleInputChange}/>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <TextAreaComponent
                  name="a_content"
                  value={rowData.a_content}
                  onChange={handleInputChange}
                ></TextAreaComponent>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center" style={{ marginTop: "10px" }}>
        <button className="btn btn_gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn_gray" onClick={() => { props.closePopup(); }}>닫기</button>
      </div>
    </div>
  );
};
export default WritePopup;