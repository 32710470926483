import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { hmenu, smenu } from "../data/menu_arr";
import strFunc from '@/lib/lyg/string';

const GNBArea = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let user = useSelector((state: any) => state.user);

  const movePage = (url: string) => {
    navigate(url);
  };

  let now_h_key = "";
  for (let i = 0; i < smenu.length; i++) {
    if (smenu[i].url == window.location.pathname) {
      now_h_key = smenu[i].hmenu;
    }
  }

  let master_menu_key_arr = [
    "manage"
  ];

  const menuArrTags = hmenu.map((item, idx) => {
    if (item.isShow == false) { return ""; }
    if (user.user_grade != "m") {
      if (strFunc.str_in_array(item.key, master_menu_key_arr) != -1) {
        return "";
      }
    }
    let gnb_class_name = "gnb_item";
    if (item.key == now_h_key) {
      gnb_class_name += " active";
    }

    return (
      <div key={idx} className={gnb_class_name} onClick={() => { movePage(item.url); }} >
        {item.name}
      </div>
    );
  });

  return (
    <div className="gnb_area">
      {menuArrTags}
    </div>
  );
};

export default GNBArea;