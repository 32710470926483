import { lazy } from 'react';

import LoginPage from "@/pages/note/login/login";
import JoinPage from "@/pages/note/login/join";
import MainPage from "@/pages/note/main";
import MyPage from '@/pages/note/basic/mypage';
import UserListPage from "@/pages/note/basic/user/list";
import DiaryListPage from "@/pages/note/note/diary/list";
import MemoListPage from "@/pages/note/note/memo/list";

const routerArr:object[] = [
  {
    path: "/",
    element: <LoginPage></LoginPage>,
  },
  {
    path: "/login",
    element: <LoginPage></LoginPage>,
  },
  {
    path: "/join",
    element: <JoinPage></JoinPage>,
  },
  {
    path: "/main",
    element: <MainPage></MainPage>,
  },
  {
    path: "/note/mypage",
    element: <MyPage></MyPage>,
  },
  {
    path: "/note/basic/user/list",
    element: <UserListPage></UserListPage>,
  },
  {
    path: "/note/note/diary/list",
    element: <DiaryListPage></DiaryListPage>,
  },
  {
    path: "/note/note/memo/list",
    element: <MemoListPage></MemoListPage>,
  },
];

export default routerArr;