import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { Link, useNavigate } from 'react-router-dom';
import WriteTableArea from "./area/write_table";

function JoinPage() {
  const navigate = useNavigate();

  const [row_data, set_row_data] = useState({
    "a_seq": "",
    "a_corp_seq": "",
    "a_user_id": "",
    "a_user_pw": "",
    "a_user_grade": "user",
    "a_is_login": "1",
    "a_user_name": "",
  });
  const [refresh, set_refresh] = useState(false);

  useEffect(() => {
    
  }, []);

  const go_join = () => {
    if (row_data.a_user_name == "") {
      alert("이름 입력이 필요합니다.");
      return false;
    }
    if (row_data.a_user_id == "") {
      alert("아이디 입력이 필요합니다.");
      return false;
    }
    if (row_data.a_user_pw == "") {
      alert("비밀번호 입력이 필요합니다.");
      return false;
    }

    if(!window.confirm("가입 하시겠습니까?")){
      return false;
    }

    let join_form_data = {
      "data_arr": [row_data],
      "is_default_val": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/join', join_form_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("가입되었습니다.");
          navigate("/login");
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  let join_box_style = {
    width: 300,
    margin: "0 auto",
    marginTop: 60,
  };

  return (
    <div style={join_box_style}>
      <h3 className="center">회원가입</h3>
      <WriteTableArea
        row_data={row_data}
        set_row_data={(inData: any) => {
          set_row_data(inData);
          set_refresh(!refresh);
        }}
      ></WriteTableArea>
      <div className="center mt-1">
        <button className="btn btn_gray" onClick={() => { go_join(); }}>저장</button>
        <button className="btn btn_gray ml-1" onClick={()=>{navigate("/login");}}>취소</button>
      </div>
    </div>
  );
}
export default JoinPage;