function WriteTableArea(props: any) {
  let myProps = {
    row_data: {},
    set_row_data: (inData: any) => { },
    ...props
  };
  let row_data = myProps.row_data;

  const handleInputChnage = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;

    let change_row_data: any = row_data;

    change_row_data[name] = value;
    myProps.set_row_data(change_row_data);
  };

  return (
    <div className="write-table-div">
      <table>
        <colgroup>
          <col width={100}></col>
          <col width={"*"}></col>
        </colgroup>
        <tbody>
          <tr>
            <th>아이디</th>
            <td>
              <input type="text" name="a_user_id" className="row_input"
                value={row_data.a_user_id}
                onChange={handleInputChnage} />
            </td>
          </tr>
          <tr>
            <th>비밀번호</th>
            <td>
              <input type="password" name="a_user_pw" className="row_input"
                value={row_data.a_user_pw}
                onChange={handleInputChnage} />
            </td>
          </tr>
          <tr>
            <th>이름</th>
            <td>
              <input type="text" name="a_user_name" className="row_input"
                value={row_data.a_user_name}
                onChange={handleInputChnage} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default WriteTableArea;