import NoteLayout from "@/pcomponents/note/layout";
import { useSelector, useDispatch } from 'react-redux';
import {removeUser,setUser } from '@/store/user/user';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import WriteArea from "./area/write";
import strFunc from "@/lib/lyg/string";

function MyPage() {
  let user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  const [row_data, set_row_data] = useState({
    "a_seq": "",
    "a_user_id": "",
    "a_user_pw": "",
    "a_user_name": "",
    "a_user_nickname": "",
  });

  useEffect(() => {
    
    get_my_user_data_by_ajax();
  }, []);

  const get_my_user_data_by_ajax = () => {
    if(strFunc.is_empty(user.user_seq)){
      return false;
    }

    let form_json_data = {
      "s_pri_arr":[user.user_seq],
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/list', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if(response.data["data"]["info_arr"].length>0){
            let row_user_info=response.data["data"]["info_arr"][0];
            row_user_info["a_user_pw"]="";
            set_row_data(row_user_info);
          }
          
        } else {

        }
      });
  };

  const write = () => {
    if(strFunc.is_empty(row_data["a_seq"])){
      alert("로그인 정보 없음.");
      return false;
    }
    if(strFunc.is_empty(row_data["a_user_name"])){
      alert("이름입력 필요.");
      return false;
    }
    if(strFunc.is_empty(row_data["a_user_id"])){
      alert("아이디입력 필요.");
      return false;
    }
    if(!window.confirm("저장 하시겠습니까?")){
      return false;
    }
    let form_json_data = {
      "data_arr":[row_data]
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/write', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("성공");
          refreshToken();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const refreshToken=() => {
    let formJsonData={
      "token_id":localStorage.login_token,
      "is_refresh":"1",
      "is_add_client_info":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/login/login/check_login',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        localStorage.login_token=response.data["data"]["token_id"];
        var client_info=response.data.data["client_info"];
        dispatch(setUser(client_info));
        get_my_user_data_by_ajax();
        alert("저장됨.");
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  return (
    <NoteLayout>
      마이페이지
      <div>
        <WriteArea
          row_data={row_data}
          set_row_data={set_row_data}
        ></WriteArea>
      </div>
      <div className="btn-box-center">
        <button className="btn btn_gray" onClick={write}>저장</button>
      </div>
    </NoteLayout>
  );
};
export default MyPage;