//import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
/*
{isPopupOpen && 
  <LayerPopup closePopup={()=>{setIsPopupOpen(false);}} title="팝업" >
    <WritePopupContent userName="ㅋㅋㅋㅋㅋ" closePopup={()=>{setIsPopupOpen(false);}}  ></WritePopupContent>
  </LayerPopup>
}
*/
import { useEffect, useRef, useState } from 'react';
import setPropsFunc from "./func/set_props/set_props";
import TitleBarArea from "./area/title_bar";

function LayerPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    callBack: () => { },
    title: '',
    width: '300px',
    height: '300px',
    x: "",
    y: "",
    is_win_max_width: true,
    is_win_max_height: true,
    title_bar_style: {},
    content_div_style: {},
    ...props
  };
  let pop_data = setPropsFunc.geDataByProps(myProps);
  const popupBackDiv = useRef<HTMLInputElement>(null);
  const [offset, setOffset] = useState({ x: pop_data.x, y: pop_data.y });

  useEffect(() => {
    popupBackDiv.current?.addEventListener("click", (e: any) => {
      if (e.target.classList.contains('popup-background')) {
        //props.closePopup();
      }
    }, false);
  }, []);

  let popup_wrap_style = {
    left: offset.x + "px",
    top: offset.y + "px",
    width: pop_data.width + "px",
    height: pop_data.height + "px",
  };

  return (
    <div className="popup-background" ref={popupBackDiv} >
      <div className="popup-transparent-back"></div>
      <div className="popup-wrap" style={popup_wrap_style}>
        <div className="popup-container">
          <TitleBarArea
            title={props.title}
            x={pop_data.x}
            y={pop_data.y}
            setOffset={setOffset}
            title_bar_style={props.title_bar_style}
            closePopup={props.closePopup}
          ></TitleBarArea>
          <div className="popup-content-div" style={props.content_div_style}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LayerPopup;
