export interface HeadMenuRow {
  key: string;
  name: string;
  url: string;
  msg: string;
  isShow: boolean;
}
export interface SubMenuRow {
  key: string;
  name: string;
  url: string;
  msg: string;
  hmenu:string;
  isShow: boolean;
}

export const hmenu:HeadMenuRow[]=[
  {key:"main",name:"테스트메인",url:"/test",msg:"",isShow:true},
  {key:"db",name:"DB",url:"/test/db/connect",msg:"",isShow:true},
  {key:"util",name:"유틸",url:"/test/util/url_str",msg:"",isShow:true},
];
export const smenu:SubMenuRow[]=[
  {key:"test_main",name:"메인",url:"/test",msg:"",hmenu:"main",isShow:true},
  {key:"db_connect",name:"DB연결",url:"/test/db/connect",msg:"",hmenu:"db",isShow:true},
  {key:"url_str",name:"URL문자",url:"/test/util/url_str",msg:"",hmenu:"util",isShow:true},
];